<!--
   Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
   SPDX-License-Identifier: MIT-0 x
-->

<title-card></title-card>
<mat-card class="mat-elevation-z0 custom" ngClass="app-card">
  <mat-card-header>
    <mat-card-title>
      Noch nicht registriert?
    </mat-card-title>
    <mat-card-subtitle>
      Um Zugang zum Exposé zu erhalten, müssen Sie zunächst den Nutzugsbedingungen zustimmen und sich registrieren.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button [color]="buttonColor1" (mouseover)="buttonColor1 = 'accent'"
      (mouseout)="buttonColor1 = 'primary'" (click)="signUp()" [disabled]="(busy1 | async)">
      <div fxFlex></div>
      <div *ngIf="!(busy1 | async)">
        Jetzt Registrieren
      </div>
      <mat-spinner *ngIf="busy1 | async" [diameter]="35"></mat-spinner>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </mat-card-actions>
</mat-card>
<mat-card class="mat-elevation-z0 custom" ngClass="app-card">
  <mat-card-header>
    <mat-card-title>
      Schon registriert? Bitte melden Sie sich an.
    </mat-card-title>
    <mat-card-subtitle>Wenn Sie schon registriert sind, geht es hier direkt zum Exposé. Geben Sie ihre
      E-Mail-Adresse
      an
      und erhalten Sie einen einmaligen Anmeldecode.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form fxLayout="column" [style.margin]="'0 16px 0 16px'">
      <mat-form-field>
        <input matInput placeholder="E-mail" [formControl]="email" type="email" email name="email"
          autocomplete="username email" />
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button [color]="buttonColor2" (mouseover)="buttonColor2 = 'accent'"
      (mouseout)="buttonColor2 = 'primary'" (click)="signIn()" [disabled]="(busy2 | async)">
      <div fxFlex></div>
      <div *ngIf="!(busy2 | async)">
        Anmelden
      </div>
      <mat-spinner *ngIf="busy2 | async" [diameter]="35"></mat-spinner>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </mat-card-actions>
  <p class="error-message" *ngIf="errorMessage | async">{{ errorMessage | async }}</p>
</mat-card>
<impressum-component></impressum-component>