import { Component } from '@angular/core';

@Component({
    selector: 'impressum-component',
    templateUrl: 'impressum.component.html',
    styleUrls: ['./impressum.component.css'],
})
export class ImpressumComponent {

}
