<!--
   Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
   SPDX-License-Identifier: MIT-0
-->
<title-card></title-card>
<!-- <mat-card class="mat-elevation-z0 custom" fxFlex="1 1 auto" ngClass="app-card"> -->
<mat-card class="mat-elevation-z0 custom" ngClass="app-card">

  <mat-card-header>
    <mat-icon mat-card-avatar>lock</mat-icon>
    <mat-card-title>Anmeldung abschließen</mat-card-title>
    <mat-card-subtitle>Bitte geben Sie den 6-stelligen Code ein, der an {{ email | async }} gesendet wurde, um die
      Anmeldung abzuschließen</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form fxLayout="row wrap" fxLayoutGap="5%">
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit1" #digit1el type="tel" pattern="\d*">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit2" #digit2el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit3" #digit3el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit4" #digit4el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit5" #digit5el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit6" #digit6el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button [color]="buttonColor" (mouseover)="buttonColor = 'accent'"
      (mouseout)="buttonColor = 'primary'" (click)="submit()">
      <div fxFlex></div>
      <div *ngIf="!(busy | async)">Weiter</div>
      <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </mat-card-actions>

  <p class="error-message" *ngIf="errorMessage | async">{{ errorMessage | async }}</p>

</mat-card>
<impressum-component></impressum-component>