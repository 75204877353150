<mat-card class="mat-elevation-z0 title-card" ngClass="app-card">
    <img class="logo" src="assets/cropped-logo5-H100px-edit.png" />
    <div>
        <div class="title mat-h1 ">
            Ein Traum an der Ostsee
        </div>
        <div class="subtitle mat-h4 ">
            Ein Immobilienangebot vom Planungsbüro G. Schulz
        </div>
    </div>
</mat-card>
<mat-divider></mat-divider>