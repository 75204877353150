<!--
   Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
   SPDX-License-Identifier: MIT-0
-->

<title-card></title-card>
<mat-toolbar>
    <button mat-raised-button [color]="buttonColor1" (mouseover)="buttonColor1 = 'accent'"
        (mouseout)="buttonColor1 = 'primary'" (click)="home()" class="example-icon" aria-label="Home">
        <mat-icon>home</mat-icon>
    </button>
    <button mat-raised-button [color]="buttonColor2" (mouseover)="buttonColor2 = 'accent'"
        (mouseout)="buttonColor2 = 'primary'" (click)="navPrevious()" class="example-icon" aria-label="Home">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-raised-button [color]="buttonColor3" (mouseover)="buttonColor3 = 'accent'"
        (mouseout)="buttonColor3 = 'primary'" (click)="navNext()" class="example-icon" aria-label="Home">
        <mat-icon>arrow_forward</mat-icon>
    </button>
</mat-toolbar>
<mat-card class="mat-elevation-z0 custom" ngClass="app-card">
    <mat-card-title>
        Exposé {{pdfLabel}}
    </mat-card-title>
    <mat-card-content *ngIf="!(pdfBusy | async)" class="custom-private">
        <p>
            Bitte haben Sie etwas Geduld, das Laden des Exposé kann einige Sekunden in Anspruch nehmen.
        </p>
    </mat-card-content>
    <mat-toolbar *ngIf="!(pdfBusy | async)">
        <a>
            Exposé lädt, bitte warten...
        </a>
        <span class="example-spacer"></span>
        <mat-spinner [color]='"Basic"' [diameter]="35"></mat-spinner>
    </mat-toolbar>
</mat-card>
<expose1-content-pdf (pdfRendered)="pdfRendered($event)" [pdfSrc]="pdfSrc"></expose1-content-pdf>
<impressum-component></impressum-component>