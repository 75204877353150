import { Component } from '@angular/core';

@Component({
    selector: 'title-card',
    templateUrl: 'title-card.component.html',
    styleUrls: ['./title-card.component.css'],
})
export class TitleCardComponent {

}
