<title-card></title-card>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="signOut()">
        <div fxFlex></div>
        <div *ngIf="!(busy | async)">Abmelden</div>
        <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
        <div fxFlex></div>
    </button>
    <button mat-menu-item (click)="openDialog()">
        <div fxFlex></div>
        <div *ngIf="!(busy | async)">{{widerrufButtonText}}</div>
        <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
        <div fxFlex></div>
    </button>
</mat-menu>
<div>
    <mat-card class="mat-elevation-z0 custom" ngClass="app-card">
        <div>
            <mat-card-title>Ostseebad Boltenhagen</mat-card-title>
        </div>
        <mat-card-header>Exklusives Baugrundstück in zentraler Lage von Boltenhagen, in Strandnähe und freiem Blick
            ins
            Grüne!
        </mat-card-header>
    </mat-card>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start center">
        <!-- <mat-card class="expose-card" (click)="goToPdf('1')">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image1"></div>
                <mat-card-title>
                    Wohnen und Wohlfühlen im Ostseebad Boltenhagen
                </mat-card-title>
                <mat-card-subtitle>
                    mit ca. 500qm großem Grundstück
                </mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="assets/house_title_small.png" alt="Karte">
            <mat-card-content class="expose-card-content">
                <p>
                    Das im Jahre 1977 errichtete Familienhaus bietet mit seinen 5 Zimmern, inklusive ausgebautem
                    Souterrain
                    und schönem Garten jede Menge Platz für die ganze Familie. Von der großzügigen Holzterrasse aus hat
                    man
                    einen unverbautem Blick nach Norden in Richtung Ostsee.
                </p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button [color]="buttonColor2" (mouseover)="buttonColor2 = 'accent'"
                    (mouseout)="buttonColor2 = 'primary'">Exposé aufrufen</button>
            </mat-card-actions>
        </mat-card> -->
        <mat-card class="expose-card" (click)="goToPdf('2')">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image2"></div>
                <mat-card-title>
                    Idyllisches Baugrundstück im Ostseebad Boltenhagen
                </mat-card-title>
                <mat-card-subtitle>
                    ca. 500qm großes Grundstück
                </mat-card-subtitle>
            </mat-card-header>
            <img mat-card-image src="assets/land_title_small.png" alt="Karte">
            <mat-card-content class="expose-card-content">
                <p>
                    Das Grundstück liegt in idyllischer Lage am Ortsrand vom Ostseebad Boltenhagen mit
                    freien Blick auf Feld und Wiesen. Der Strand ist ca. 250m entfernt.
                </p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button [color]="buttonColor3" (mouseover)="buttonColor3 = 'accent'"
                    (mouseout)="buttonColor3 = 'primary'">Exposé aufrufen</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
<div style="padding-top: 14px;" fxLayout="row" fxLayoutAlign="space-around center">
    <div></div>
    <img mat-card-image src=" assets/dop4_kreis.png" alt="Karte" style="width: 97%;">
    <div></div>
</div>
<mat-toolbar>
    <span class="example-spacer"></span>
    <button mat-raised-button [color]="buttonColor1" (mouseover)="buttonColor1 = 'accent'"
        (mouseout)="buttonColor1 = 'primary'" [matMenuTriggerFor]="menu" class="example-icon"
        aria-label="Example icon-button with menu icon">
        <mat-icon>account_circle</mat-icon><span> Abmelden/Widerruf</span>
    </button>
    <span class="example-spacer"></span>
</mat-toolbar>
<impressum-component></impressum-component>